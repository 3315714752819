import { applyMiddleware, createStore, Middleware } from 'redux'
import logger from 'redux-logger'
import { persistStore } from 'redux-persist'
import rootReducer from './root-reducer'
import createSagaMiddleware from 'redux-saga'
import { fetchCollectionsStart } from './shop/shop.sagas'
const sagaMiddleWare = createSagaMiddleware()

const middlewares: Middleware<any, {}, any>[] = [sagaMiddleWare]

if (process.env.NODE_ENV === 'development') middlewares.push(logger)

export const store = createStore(rootReducer, applyMiddleware(...middlewares))

sagaMiddleWare.run(fetchCollectionsStart)

// @ts-ignore
export const persistor = persistStore(store)
