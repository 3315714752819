import "./collection-preview.styles.scss";

import {
  CollectionItem,
  Item,
} from "../collection-item/collection-item.component";

export type Collection = {
  id: number;
  title: string;
  routeName: string;
  items: Item[];
};

export type CollectionProps = Omit<Collection, "id">;

export const CollectionPreview = ({ title, items }: CollectionProps) => {
  return (
    <div className="collection-preview">
      <h1 className="title">{title.toUpperCase()}</h1>
      <div className="preview">
        {items.slice(0, 4).map((item) => (
          <CollectionItem key={item.id} {...item} />
        ))}
      </div>
    </div>
  );
};
