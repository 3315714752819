import { useSelector } from 'react-redux'
import {
  selectIsCollectionFetching,
  selectShopCollectionsForPreview,
} from '../../redux/shop/shop.selectors'
import {
  Collection,
  CollectionPreview,
} from '../collection-preview/collection-preview.component'
import { SpinnerComponentIf } from '../with-spinner/with-spinner.component'
import './collections-overview.styles.scss'

export const CollectionsOverview = () => {
  const collections: Collection[] = useSelector(selectShopCollectionsForPreview)
  return (
    <div className="collections-overview">
      {collections.map(({ id, routeName, title, items }) => (
        <CollectionPreview
          key={id}
          routeName={routeName}
          title={title}
          items={items}
        ></CollectionPreview>
      ))}
    </div>
  )
}

export const CollectionsOverviewContainer = () => {
  const isLoading = useSelector(selectIsCollectionFetching)
  const Component = SpinnerComponentIf(isLoading, CollectionsOverview)
  return <Component />
}
