import { memoize } from 'lodash'
import { createSelector } from 'reselect'
import { RootState } from '../root-reducer'

export const selectShop = (state: RootState) => state.shop

export const selectShopCollections = createSelector(
  [selectShop],
  (shop) => shop.collections,
)

export const selectShopCollectionsForPreview = createSelector(
  [selectShopCollections],
  (collections) =>
    Object.keys(collections ?? []).map(
      (collectionId) => collections[collectionId],
    ),
)

export const selectCollection = memoize((collectionUrlParam: string) =>
  createSelector(
    [selectShopCollections],
    (collections) => collections?.[collectionUrlParam] ?? [],
  ),
)

export const selectIsCollectionFetching = createSelector(
  [selectShop],
  (shop) => shop.isFetching,
)

export const selectIsCollectionsLoaded = createSelector(
  [selectShop],
  (shop) => !!shop.collections,
)
