import { useCallback, useState } from 'react'
import './sign-up.styles.scss'
import { FormInput } from '../form-input/form-input.component'
import { CustomButton } from '../custom-button/custom-button.component'
import { auth, createUserProfileDocument } from '../../firebase/firebase.utils'

type UserInfo = {
  displayName: string
  email: string
  password: string
  confirmPassword: string
}

type UserInfoKeys = keyof UserInfo

export const SignUp = () => {
  const [userInfo, setUserInfo] = useState<UserInfo>({
    displayName: '',
    email: '',
    password: '',
    confirmPassword: '',
  })
  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault()

      const { displayName, email, password, confirmPassword } = userInfo

      if (password !== confirmPassword) {
        alert(`Passwords don't match`)
        return
      }

      try {
        const { user } = await auth.createUserWithEmailAndPassword(
          email,
          password,
        )
        if (user) {
          createUserProfileDocument(user, { displayName })
          setUserInfo({
            displayName: '',
            email: '',
            password: '',
            confirmPassword: '',
          })
        }
      } catch (error) {
        console.error(error)
      }
    },
    [userInfo],
  )

  const handleChange = useCallback(
    (event) => {
      const { name, value }: { name: UserInfoKeys; value: string } =
        event.target
      setUserInfo({ ...userInfo, [name]: value })
    },
    [userInfo],
  )

  return (
    <div className="sign-up">
      <h2 className="title">I do not have an account</h2>
      <span>Sign up with your email and password</span>
      <form className="sign-up-form" onSubmit={handleSubmit}>
        <FormInput
          type="text"
          name="displayName"
          label="Display Name"
          value={userInfo.displayName}
          handleChange={handleChange}
          required
        />
        <FormInput
          type="email"
          name="email"
          label="Email"
          value={userInfo.email}
          handleChange={handleChange}
          required
        />
        <FormInput
          type="password"
          name="password"
          label="Password"
          value={userInfo.password}
          handleChange={handleChange}
          required
        />
        <FormInput
          type="password"
          name="confirmPassword"
          label="Confirm Password"
          value={userInfo.confirmPassword}
          handleChange={handleChange}
          required
        />
        <CustomButton type="submit">SIGN UP</CustomButton>
      </form>
    </div>
  )
}
