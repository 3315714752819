import { useSelector } from 'react-redux'
import { CheckoutItem } from '../../components/checkout-item/checkout-item.component'
import { StripeCheckoutButton } from '../../components/stripe-button/stripe-button.component'
import {
  selectCartItems,
  selectCartTotal,
} from '../../redux/cart/cart.selectors'
import './checkout.styles.scss'

const blocks = ['Product', 'Description', 'Quantity', 'Price', 'Remove']

export const CheckoutPage = () => {
  const cartItems = useSelector(selectCartItems)
  const cartTotal = useSelector(selectCartTotal)
  return (
    <div className="checkout-page">
      <div className="checkout-header">
        {blocks.map((block) => (
          <div className="header-block">
            <span>{block}</span>
          </div>
        ))}
      </div>
      {cartItems.map((item) => (
        <CheckoutItem key={item.id} item={item} />
      ))}
      <div className="total">
        <span>TOTAL: ${cartTotal}</span>
      </div>
      <div className="test-warning">
        * Please use the following test credit card for payments*
        <br />
        42424 42424 42424 42424 12/22 123
      </div>
      <StripeCheckoutButton price={cartTotal} />
    </div>
  )
}
