import './stripe-button.styles.scss'
import StripeCheckout, { Token } from 'react-stripe-checkout'

type Props = {
  price: number
}

const publishableKey =
  'pk_test_51J43aeGCBe8k0QRqKUr6ADw9B3XSRt6wBU85OGtJnO3vyBASaS7Y55gXML62AY0gZ1fNFg3xhlwPpR0hIbgZHES400FafNJ0HK'

const onToken = (token: Token) => {
  console.warn('Payment processed', token)
}
export const StripeCheckoutButton = ({ price }: Props) => {
  const priceForStripe = price * 100

  return (
    <StripeCheckout
      amount={priceForStripe}
      label="Pay Now"
      name="CRWN Clothing"
      billingAddress
      shippingAddress
      image="https://svgshare.com/i/CUz.svg"
      description={`Your total is $${price}`}
      panelLabel="Pay Now"
      token={onToken}
      stripeKey={publishableKey}
    />
  )
}
