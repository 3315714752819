import { Item } from '../../components/collection-item/collection-item.component'

export type Collection = {
  id: number
  title: string
  routeName: string
  items: Item[]
}

export type Collections = { [key: string]: Collection }
export type ShopState = {
  collections: Collections
  isFetching: boolean
  errorMessage?: string
}

const INITIAL_STATE = {
  collections: null,
  isFetching: false,
  errorMessage: undefined,
}

export enum ShopActions {
  FETCH_COLLECTIONS_START = 'FETCH_COLLECTIONS_START',
  FETCH_COLLECTIONS_SUCCESS = 'FETCH_COLLECTIONS_SUCCESS',
  FETCH_COLLECTIONS_FAILURE = 'FETCH_COLLECTIONS_FAILURE',
}

type ShopActionTypes =
  | {
      type: ShopActions.FETCH_COLLECTIONS_START
      payload: unknown
    }
  | {
      type: ShopActions.FETCH_COLLECTIONS_SUCCESS
      payload: unknown
    }
  | {
      type: ShopActions.FETCH_COLLECTIONS_FAILURE
      payload: string
    }

export const shopReducer = (
  state: ShopState = INITIAL_STATE,
  action: ShopActionTypes,
) => {
  switch (action.type) {
    case ShopActions.FETCH_COLLECTIONS_START:
      return {
        ...state,
        isFetching: true,
        errorMessage: undefined,
      }
    case ShopActions.FETCH_COLLECTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        collections: action.payload,
      }
    case ShopActions.FETCH_COLLECTIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      }
    default:
      return state
  }
}
