import { Item } from '../../components/collection-item/collection-item.component'
import { CartItem } from './cart.reducer'

export const addItemToCartHelper = (cartItems: Array<CartItem>, item: Item) => {
  const cartItemsCopy = cartItems.map((cartItem) => cartItem)
  const cartItem = cartItemsCopy.find((cartItem) => cartItem.id === item.id)
  if (cartItem) {
    cartItem.quantity += 1
  } else {
    cartItemsCopy.push({ ...item, quantity: 1 })
  }
  return cartItemsCopy
}

export const reduceItemQuantityInCartHelper = (
  cartItems: Array<CartItem>,
  item: Item,
) => {
  const cartItemsCopy = cartItems.map((cartItem) => cartItem)
  const cartItemIndex = cartItemsCopy.findIndex(
    (cartItem) => cartItem.id === item.id,
  )
  if (cartItemIndex < 0) return cartItemsCopy
  if (cartItemsCopy[cartItemIndex].quantity > 1) {
    cartItemsCopy[cartItemIndex].quantity =
      cartItemsCopy[cartItemIndex].quantity - 1
  } else {
    cartItemsCopy.splice(cartItemIndex, 1)
  }
  return cartItemsCopy
}

export const removeItemFromCartHelper = (
  cartItems: Array<CartItem>,
  item: Item,
) =>
  cartItems
    .map((cartItem) => cartItem)
    .filter((cartItem) => cartItem.id !== item.id)
