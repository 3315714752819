import { Item } from '../../components/collection-item/collection-item.component'
import { CartActions } from './cart.reducer'

export const toggleCartHidden = () => ({
  type: CartActions.TOGGLE_CART_HIDDEN,
})

export const addItemToCart = (item: Item) => ({
  type: CartActions.ADD_ITEM,
  payload: item,
})

export const reduceItemQuantityInCart = (item: Item) => ({
  type: CartActions.REDUCE_ITEM_QUANTITY,
  payload: item,
})

export const removeItemFromCart = (item: Item) => ({
  type: CartActions.REMOVE_ITEM,
  payload: item,
})
