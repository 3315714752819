import './App.css'

import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom'

import { Header } from './components/header/header.component'
import firebase, {
  auth,
  createUserProfileDocument,
  FirebaseUser,
} from './firebase/firebase.utils'
import { CheckoutPage } from './pages/checkout/checkout.component'
import { HomePage } from './pages/homepage/homepage.component'
import { ShopPage } from './pages/shop/shop.component'
import { SignInAndSignUpPage } from './pages/sign-in-and-sign-up/sign-in-and-sign-up.component'
import { setCurrentUserAction } from './redux/user/user.actions'
import { UserInfo } from './redux/user/user.reducer'
import { selectCurrentUser } from './redux/user/user.selector'

export const AuthContext = React.createContext<{ currentUser: FirebaseUser }>({
  currentUser: null,
})

const App = () => {
  const dispatch = useDispatch()
  const dispatchRef = useRef(dispatch)
  dispatchRef.current = dispatch
  const currentUser = useSelector(selectCurrentUser)

  const unsubscribeRef = useRef<firebase.Unsubscribe | null>(null)
  useEffect(() => {
    unsubscribeRef.current = auth.onAuthStateChanged(async (user) => {
      if (!!user) {
        const userRef = await createUserProfileDocument(user)
        userRef.onSnapshot((snapshot) => {
          dispatchRef.current(
            setCurrentUserAction({
              id: snapshot.id,
              ...snapshot.data(),
            } as UserInfo),
          )
        })
      }

      dispatchRef.current(setCurrentUserAction(null))
    })

    return () => {
      unsubscribeRef.current && unsubscribeRef.current()
    }
  }, [])

  const renderSignIn = useCallback(
    () =>
      currentUser !== null ? <Redirect to="/" /> : <SignInAndSignUpPage />,
    [currentUser],
  )

  return (
    <div>
      <Router>
        <Route component={Header} />
        <Route exact path="/" component={HomePage} />
        <Route path="/shop" component={ShopPage} />
        <Route exact path="/signIn" render={renderSignIn} />
        <Route exact path="/checkout" component={CheckoutPage} />
      </Router>
    </div>
  )
}

export default App
