import './collection.styles.scss'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { CollectionItem } from '../../components/collection-item/collection-item.component'
import { Collection } from '../../components/collection-preview/collection-preview.component'
import {
  selectCollection,
  selectIsCollectionsLoaded,
} from '../../redux/shop/shop.selectors'
import { SpinnerComponentIf } from '../../components/with-spinner/with-spinner.component'

export const CollectionPage = () => {
  const {
    params: { categoryId },
  } = useRouteMatch<{ categoryId: string }>()
  const { title, items } = (useSelector(
    selectCollection(categoryId.toLowerCase()),
  ) as Collection) ?? { title: undefined, items: [] }

  return (
    <div className="collection-page">
      <h2 className="title">{title}</h2>
      <div className="items">
        {items.map((item) => (
          <CollectionItem key={item.id} {...item} />
        ))}
      </div>
    </div>
  )
}

export const CollectionsContainer = () => {
  const isCollectionsLoaded = useSelector(selectIsCollectionsLoaded)
  const Component = SpinnerComponentIf(!isCollectionsLoaded, CollectionPage)
  return <Component />
}
