import React, { FunctionComponent } from 'react'
import { CustomButtonContainer } from './custom-button.styles'

export type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  isGoogleSignIn?: boolean
  inverted?: boolean
  ref?: React.Ref<HTMLButtonElement>
}

export const CustomButton: FunctionComponent<ButtonProps> = ({
  children,
  isGoogleSignIn = false,
  inverted = false,
  ...otherProps
}: ButtonProps) => (
  <CustomButtonContainer
    isGoogleSignIn={isGoogleSignIn}
    inverted={inverted}
    {...otherProps}
  >
    {children}
  </CustomButtonContainer>
)
