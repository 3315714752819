import 'firebase/auth'
import 'firebase/firestore'

import firebase from 'firebase/app'

firebase.initializeApp({
  apiKey: 'AIzaSyCwsmfSSs2qA0f5TAxxePsv2VCR6CAApOM',
  authDomain: 'crwn-db-1869c.firebaseapp.com',
  projectId: 'crwn-db-1869c',
  storageBucket: 'crwn-db-1869c.appspot.com',
  messagingSenderId: '83667564174',
  appId: '1:83667564174:web:39acd8c29fe38138983bdf',
  measurementId: 'G-V10QSF6PW4',
})

export const auth = firebase.auth()
export const firestore = firebase.firestore()

const provider = new firebase.auth.GoogleAuthProvider()
provider.setCustomParameters({ prompt: 'select_account' })

export const signInWithGoogle = () => auth.signInWithPopup(provider)

export default firebase
export type FirebaseUser = Partial<firebase.User & { id: string }> | null

export const createUserProfileDocument = async (
  userAuth: firebase.User,
  additionalData: Object = {},
) => {
  const userRef = firestore.collection('users').doc(userAuth.uid)
  const snapshot = await userRef.get()
  if (!snapshot.exists) {
    const { displayName, email } = userAuth
    const createdAt = new Date()

    try {
      await userRef.set({
        displayName,
        email,
        createdAt,
        ...additionalData,
      })
    } catch (error) {
      console.error('error creating user', error.message)
    }
  }
  return userRef
}

export const addCollectionAndDocuments = async (
  collectionKey,
  objectsToAdd,
) => {
  const collectionRef = firestore.collection(collectionKey)

  const batch = firestore.batch()

  objectsToAdd.forEach((object) => {
    const newDocRef = collectionRef.doc()
    batch.set(newDocRef, object)
  })
  await batch.commit()
}

export const convertCollectionsSnapshotToMap = (collections) => {
  const transformedCollection = collections.docs.map((doc) => {
    const { title, items } = doc.data()
    return {
      routeName: encodeURI(title.toLowerCase()),
      id: doc.id,
      title,
      items,
    }
  })

  return transformedCollection.reduce((accumulator, collection) => {
    accumulator[collection.title.toLowerCase()] = collection
    return accumulator
  }, {})
}
