import { SpinnerContainer, SpinnerOverlay } from './with-spinner.styles'

export const SpinnerComponent = () => (
  <SpinnerOverlay>
    <SpinnerContainer />
  </SpinnerOverlay>
)

export const SpinnerComponentIf = (
  isLoading: boolean,
  LoadedComponent: React.ComponentType<any>,
): React.ComponentType<any> => (isLoading ? SpinnerComponent : LoadedComponent)
