import { ChangeEventHandler, useCallback, useState } from "react";
import "./sign-in.styles.scss";
import { FormInput } from "../form-input/form-input.component";
import { CustomButton } from "../custom-button/custom-button.component";

import { auth, signInWithGoogle } from "../../firebase/firebase.utils";

export const SignIn = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        await auth.signInWithEmailAndPassword(username, password);
        setUsername("");
        setPassword("");
      } catch (error) {
        console.error(error);
      }
    },
    [username, password]
  );

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value, name } }) =>
      (name === "email" ? setUsername : setPassword)(value),
    []
  );

  return (
    <div className="sign-in">
      <h2>I already have an account</h2>
      <span>Sing in with your email and password</span>
      <form onSubmit={onSubmit}>
        <FormInput
          name="email"
          label="email"
          value={username}
          required
          handleChange={onChange}
        />
        <FormInput
          name="password"
          type="password"
          label="password"
          value={password}
          required
          handleChange={onChange}
        />
        <div className="buttons">
          <CustomButton type="submit">Sign in</CustomButton>
          <CustomButton type="button" onClick={signInWithGoogle} isGoogleSignIn>
            Sign in with google
          </CustomButton>
        </div>
      </form>
    </div>
  );
};
