import { ChangeEventHandler } from "react";
import "./form-input.styles.scss";

type FormInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & { label?: string; handleChange: ChangeEventHandler<HTMLInputElement> };

export const FormInput = ({
  handleChange,
  label,
  ...otherProps
}: FormInputProps) => (
  <div className="group">
    <input className="form-input" onChange={handleChange} {...otherProps} />
    {label ? (
      <label
        className={`${
          `${otherProps.value}`.length ? "shrink" : ""
        } form-input-label`}
      >
        {label}
      </label>
    ) : null}
  </div>
);
