import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  addItemToCart,
  reduceItemQuantityInCart,
  removeItemFromCart,
} from '../../redux/cart/cart.actions'
import { CartItem } from '../../redux/cart/cart.reducer'
import './checkout-item.styles.scss'

type Props = {
  item: CartItem
}

export const CheckoutItem = ({ item }: Props) => {
  const dispatch = useDispatch()
  const remove = useCallback(
    () => dispatch(removeItemFromCart(item)),
    [dispatch, item],
  )
  const increaseQuantity = useCallback(
    () => dispatch(addItemToCart(item)),
    [dispatch, item],
  )
  const decreaseQuantity = useCallback(
    () => dispatch(reduceItemQuantityInCart(item)),
    [dispatch, item],
  )

  const { name, imageUrl, price, quantity } = item
  return (
    <div className="checkout-item">
      <div className="image-container">
        <img src={imageUrl} alt="item" />
      </div>
      <span className="name">{name}</span>
      <span className="quantity">
        <div className="arrow" onClick={decreaseQuantity}>
          &#10094;
        </div>
        <span className="value">{quantity}</span>
        <div className="arrow" onClick={increaseQuantity}>
          &#10095;
        </div>
      </span>
      <span className="price">{price}</span>
      <div className="remove-button" onClick={remove}>
        &#10005;
      </div>
    </div>
  )
}
