import { takeEvery, call, put } from '@redux-saga/core/effects'
import {
  firestore,
  convertCollectionsSnapshotToMap,
} from '../../firebase/firebase.utils'
import { fetchCollectionsSuccess, fetchCollectionsError } from './shop.actions'
import { ShopActions } from './shop.reducer'

export function* fetchCollectionsAsync() {
  try {
    const collectionsFromFirestore = firestore.collection('collections')
    const snapshot = yield collectionsFromFirestore.get()
    const collectionsMap = yield call(convertCollectionsSnapshotToMap, snapshot)
    yield put(fetchCollectionsSuccess(collectionsMap))
  } catch (error) {
    yield put(fetchCollectionsError(error.message))
  }
}

export function* fetchCollectionsStart() {
  yield takeEvery(ShopActions.FETCH_COLLECTIONS_START, fetchCollectionsAsync)
}
