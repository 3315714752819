import './cart-icon.styles.scss'

import { ReactComponent as ShoppingIcon } from '../../assets/shopping-bag.svg'
import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { toggleCartHidden } from '../../redux/cart/cart.actions'

type CartIconProps = {
  count: number
}

export const CartIcon = ({ count }: CartIconProps) => {
  const dispatch = useDispatch()
  return (
    <div
      className="cart-icon"
      onClick={useCallback(() => dispatch(toggleCartHidden()), [dispatch])}
    >
      <ShoppingIcon className="shopping-icon" />
      <span className="item-count">{count}</span>
    </div>
  )
}
