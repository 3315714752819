import { createSelector } from 'reselect'
import { RootState } from '../root-reducer'
import { CartItem, CartState } from './cart.reducer'

const selectCart = (state: RootState) => state.cart

export const selectCartHidden = createSelector(
  [selectCart],
  (cart: CartState) => cart.hidden,
)

export const selectCartItems = createSelector(
  [selectCart],
  (cart: CartState) => cart.cartItems,
)

export const selectCartItemsCount = createSelector(
  [selectCartItems],
  (cartItems: Array<CartItem>) =>
    cartItems.reduce((acc, currentItem) => acc + currentItem.quantity, 0),
)

export const selectCartTotal = createSelector(
  [selectCartItems],
  (cartItems: Array<CartItem>) =>
    cartItems.reduce(
      (acc, currentItem) => acc + currentItem.quantity * currentItem.price,
      0,
    ),
)
