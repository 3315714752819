import { Item } from '../../components/collection-item/collection-item.component'
import {
  addItemToCartHelper,
  reduceItemQuantityInCartHelper,
  removeItemFromCartHelper,
} from './cart.utils'

export type CartItem = Item & { quantity: number }

export type CartState = {
  hidden: boolean
  cartItems: Array<CartItem>
}

const INITIAL_STATE: CartState = {
  hidden: true,
  cartItems: [],
}

export enum CartActions {
  TOGGLE_CART_HIDDEN = 'TOGGLE_CART_HIDDEN',
  ADD_ITEM = 'ADD_ITEM',
  REMOVE_ITEM = 'REMOVE_ITEM',
  REDUCE_ITEM_QUANTITY = 'REDUCE_ITEM_QUANTITY',
}

type CartActionTypes =
  | {
      type: CartActions.TOGGLE_CART_HIDDEN
      payload: undefined
    }
  | {
      type: CartActions.ADD_ITEM
      payload: Item
    }
  | {
      type: CartActions.REMOVE_ITEM
      payload: Item
    }
  | {
      type: CartActions.REDUCE_ITEM_QUANTITY
      payload: Item
    }

export const cartReducer = (state = INITIAL_STATE, action: CartActionTypes) => {
  switch (action.type) {
    case CartActions.TOGGLE_CART_HIDDEN:
      return {
        ...state,
        hidden: !state.hidden,
      }
    case CartActions.ADD_ITEM:
      return {
        ...state,
        cartItems: addItemToCartHelper(state.cartItems, action.payload),
      }
    case CartActions.REDUCE_ITEM_QUANTITY:
      return {
        ...state,
        cartItems: reduceItemQuantityInCartHelper(
          state.cartItems,
          action.payload,
        ),
      }
    case CartActions.REMOVE_ITEM:
      return {
        ...state,
        cartItems: removeItemFromCartHelper(state.cartItems, action.payload),
      }
    default:
      return state
  }
}
