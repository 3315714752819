import { camelCase } from 'lodash'
import { ReactComponent as Logo } from '../../assets/crown.svg'

import { auth } from '../../firebase/firebase.utils'

import './header.styles.ts'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { CartIcon } from '../cart-icon/cart-icon.component'
import { CartDropdown } from '../cart-dropdown/cart-dropdown.component'
import {
  selectCartHidden,
  selectCartItemsCount,
} from '../../redux/cart/cart.selectors'
import { selectCurrentUser } from '../../redux/user/user.selector'
import {
  HeaderContainer,
  LogoContainer,
  OptionLink,
  OptionsContainer,
} from './header.styles'

const options = ['shop', 'contact']

export const Header = () => {
  const currentUser = useSelector(selectCurrentUser)
  const cartHidden = useSelector(selectCartHidden)
  const numberOfItemsInCart = useSelector(selectCartItemsCount)
  const signOut = useCallback(
    () => currentUser && auth.signOut(),
    [currentUser],
  )

  return (
    <HeaderContainer>
      <LogoContainer to="/">
        <Logo className="logo" />
      </LogoContainer>
      <OptionsContainer>
        {options.map((option) => (
          <OptionLink to={`/${camelCase(option)}`}>
            {option.toUpperCase()}
          </OptionLink>
        ))}
        {!!currentUser ? (
          <OptionLink as={'div'} to="" onClick={signOut}>
            SIGN OUT
          </OptionLink>
        ) : (
          <OptionLink to="/signin">SIGN IN</OptionLink>
        )}
        <CartIcon count={numberOfItemsInCart} />
      </OptionsContainer>
      {!cartHidden && <CartDropdown />}
    </HeaderContainer>
  )
}
