import { useCallback } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import './menu-item.styles.scss'

type Props = {
  title: string
  subtitle: string
  imageUrl: string
  linkUrl: string
  size?: string
}

export const MenuItem = ({
  title,
  subtitle,
  imageUrl,
  size,
  linkUrl,
}: Props) => {
  const history = useHistory()
  const { url } = useRouteMatch()
  const navigate = useCallback(
    () => history.push(`${url}${linkUrl}`),
    [history, url, linkUrl],
  )
  return (
    <div className={`${size} menu-item`} onClick={navigate}>
      <div
        className="background-image"
        style={{ backgroundImage: `url(${imageUrl})` }}
      />
      <div className="content">
        <h1 className="title">{title.toUpperCase()}</h1>
        <span className="subtitle">{subtitle}</span>
      </div>
    </div>
  )
}
