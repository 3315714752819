import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { toggleCartHidden } from '../../redux/cart/cart.actions'
import { selectCartItems } from '../../redux/cart/cart.selectors'
import { CartItem } from '../cart-item/cart-item.component'
import { CustomButton } from '../custom-button/custom-button.component'
import './cart-dropdown.styles.scss'

export const CartDropdown = () => {
  const cartItems = useSelector(selectCartItems)
  const history = useHistory()
  const dispatch = useDispatch()
  return (
    <div className="cart-dropdown">
      <div className="cart-items">
        {cartItems.length ? (
          cartItems.map((cartItem) => <CartItem item={cartItem} />)
        ) : (
          <span className="empty-message">Your cart is empty</span>
        )}
      </div>
      <CustomButton
        onClick={useCallback(() => {
          history.push('checkout')
          dispatch(toggleCartHidden())
        }, [history, dispatch])}
      >
        GO TO CHECKOUT
      </CustomButton>
    </div>
  )
}
