import { CollectionsOverviewContainer } from '../../components/collections-overview/collections-overview.component'
import { Route, useRouteMatch } from 'react-router-dom'
import { CollectionsContainer } from '../collection/collection.component'
import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { fetchCollectionsStart } from '../../redux/shop/shop.actions'

export const ShopPage = () => {
  const { path } = useRouteMatch()
  const dispatch = useDispatch()
  const dispatchRef = useRef(dispatch)
  useEffect(() => {
    dispatchRef.current?.(fetchCollectionsStart())
  }, [])

  return (
    <div className="shop-page">
      <Route exact path={path} component={CollectionsOverviewContainer} />
      <Route path={`${path}/:categoryId`} component={CollectionsContainer} />
    </div>
  )
}
