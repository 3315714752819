import {
  convertCollectionsSnapshotToMap,
  firestore,
} from '../../firebase/firebase.utils'
import { ShopActions } from './shop.reducer'

export const fetchCollectionsStart = () => ({
  type: ShopActions.FETCH_COLLECTIONS_START,
})

export const fetchCollectionsSuccess = (collectionsMap: unknown) => ({
  type: ShopActions.FETCH_COLLECTIONS_SUCCESS,
  payload: collectionsMap,
})

export const fetchCollectionsError = (errorMessage: string) => ({
  type: ShopActions.FETCH_COLLECTIONS_FAILURE,
  payload: errorMessage,
})

export const fetchCollectionsStartAsync = () => (dispatch) => {
  dispatch(fetchCollectionsStart())

  const collectionsFromFirestore = firestore.collection('collections')
  collectionsFromFirestore
    .get()
    .then((snapshot) =>
      dispatch(
        fetchCollectionsSuccess(convertCollectionsSnapshotToMap(snapshot)),
      ),
    )
    .catch((error) => dispatch(fetchCollectionsError(error.message)))
}
