import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { addItemToCart } from '../../redux/cart/cart.actions'
import { CustomButton } from '../custom-button/custom-button.component'
import './collection-item.styles.scss'

export type Item = {
  id: number
  imageUrl: string
  name: string
  price: number
}

export const CollectionItem = (item: Item) => {
  const { imageUrl, name, price } = item
  const dispatch = useDispatch()
  return (
    <div className="collection-item">
      <div className="image" style={{ backgroundImage: `url(${imageUrl})` }} />
      <div className="collection-footer">
        <span className="name">{name}</span>
        <span className="price">{price}</span>
      </div>
      <CustomButton
        inverted
        onClick={useCallback(
          () => dispatch(addItemToCart(item)),
          [dispatch, item],
        )}
      >
        Add to cart
      </CustomButton>
    </div>
  )
}
