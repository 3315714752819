export type UserInfo = {
  id: string
  displayName: string
  createdAt: {
    seconds: number
    nanoseconds: number
  }
  email: string
} | null

export type UserState = {
  currentUser: UserInfo
}

const INITIAL_STATE: UserState = {
  currentUser: null,
}

export enum UserActions {
  SET_CURRENT_USER = 'SET_CURRENT_USER',
}

type UserActionTypes = {
  type: UserActions.SET_CURRENT_USER
  payload: UserInfo
}

export const userReducer = (state = INITIAL_STATE, action: UserActionTypes) => {
  switch (action.type) {
    case UserActions.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      }

    default:
      return state
  }
}
